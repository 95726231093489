import React from 'react';
import './index.css';
import App from './pages/App';
import Nutzungsbedingungen from './pages/nutzungsbedingungen';
import reportWebVitals from './reportWebVitals';
import ReactDOM from 'react-dom';
import '@fontsource-variable/rubik';
 
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <App />
    ),
  },
  {
    path: "nutzungsbedingungen",
    element: <Nutzungsbedingungen />,
  },
]);

ReactDOM.render((
  <React.StrictMode>
    <div className="App" style={{ position: "relative" }}>
      <RouterProvider router={router} />
    </div>
  </React.StrictMode>
), document.getElementById('root'));


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();




