/** @jsxImportSource @emotion/react */
import "@fontsource/noto-sans/300.css";
import "@fontsource/noto-sans/400.css";
import "@fontsource/noto-sans/500.css";
import "@fontsource/noto-sans/700.css";
import { Container, Link, Accordion, AccordionSummary, AccordionDetails, Typography, Stack, Box, Card } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../assets/css/App.css";
import { Footer } from "../components/footer";
import { Navbar } from "../components/navbar";
import { Link as RouterLink } from "react-router-dom"
const bg = require("../assets/img/bg-blau.jpg");

function App() {
  return (
    <>
      <Navbar />
      <Box sx={{ marginY: "48px", paddingX: { xs: 0, sm: 0 } }}>
        {/* BackgroundImage */}
        <div style={{ position: "absolute", width: "100%", height: "100%", top: 0, zIndex: -1, overflow: "hidden" }}>
          <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", backgroundColor: "#030a33" }}>
            <img
              src={bg}
              className="bg"
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
            />
          </div>

        </div>
        {/* Content */}
        <Container maxWidth="md" sx={{ paddingX: { xs: 2, sm: 4 }, position: "relative", zIndex: 10 }}>
          <Card sx={{ paddingY: "48px", borderRadius: "5px", paddingX: { xs: 2, sm: 4 } }}>
            <Typography variant="h4" fontWeight={"500"} marginBottom={"32px"}>Häufig gestellte Fragen & Antworten</Typography>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#e73149" }} />} aria-controls="panel2a-content" id="panel2a-header">
                <Typography variant="subtitle1">Ich kann mich nicht einloggen, was kann ich tun?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ fontWeight: 300 }}>Wenn du dich nicht einloggen kannst, vergewissere dich zunächst, ob deine Login-Daten korrekt sind. Falls du dir nicht sicher bist, nutze bitte die Passwort-zurücksetzen-Funktion unter Login. Solltest du dir sicher sein, dass deine Daten korrekt sind und du bereits ein Benutzerkonto erstellt hast, schreibe eine E-Mail an unseren technischen Support info@youraby.de mit der Angabe deiner E-Mail-Adresse und der Buchungsnummer deiner Reise, falls du schon eine hast.</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#e73149" }} />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="subtitle1">Wie lauten meine Login-Daten?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ fontWeight: 300 }}>Deine Login-Daten bestehen aus deiner E-Mail-Adresse und einem Passwort, welche du bei deiner Reise angegeben hast. Wenn du keine Reise gebucht hast, kannst du in der App unter "Registrieren" ein Benutzerkonto erstellen.</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#e73149" }} />} aria-controls="panel2a-content" id="panel2a-header">
                <Typography variant="subtitle1">Ich habe mein Passwort vergessen. Wie erstelle ich ein neues?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ fontWeight: 300 }}>In der App findest du unter Login einen "Passwort vergessen"-Link. Folge den Anweisungen in der App. Du bekommst eine E-Mail mit einem Link zur Vergabe eines neuen Passworts an deine E-Mail-Adresse geschickt.</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#e73149" }} />} aria-controls="panel2a-content" id="panel2a-header">
                <Typography variant="subtitle1">Ich möchte mein Passwort ändern. Wie kann ich es ändern?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ fontWeight: 300 }}>Das Passwort kannst du in den Einstellungen unter "Account" ändern. Dort findest du einen Button mit der "Passwort ändern"-Funktion.</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#e73149" }} />} aria-controls="panel2a-content" id="panel2a-header">
                <Typography variant="subtitle1">Ich möchte die E-Mail-Adresse meines Benutzerkontos ändern, wie kann ich das machen?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ fontWeight: 300 }}>Die E-Mail-Adresse deines Accounts kannst du unter Einstellungen und dann unter Account ändern. Dort findest du den Button mit der "E-Mail ändern"-Funktion.</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#e73149" }} />} aria-controls="panel2a-content" id="panel2a-header">
                <Typography variant="subtitle1">Der Timetable zeigt keine Termine an. Woran liegt das?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ fontWeight: 300 }}>Vergewissere dich zunächst, ob du eine Buchung bereits hinzugefügt hast. Falls dies der Fall ist, kann es sein, dass unsere Reiseleitung noch keinen Timetable veröffentlicht hat. Gedulde dich noch etwas oder melde dich gerne bei deiner Reiseleitung.</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#e73149" }} />} aria-controls="panel2a-content" id="panel2a-header">
                <Typography variant="subtitle1">Wie bezahle ich meine Buchungen?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ fontWeight: 300 }}>In der Navigation unter "Rechnungen" findest du die Rechnungen zu deinen Buchungen. Dort findest du die genauen Beträge und die Zahlungsmöglichkeiten zu deiner Reise.</Typography>
              </AccordionDetails>
            </Accordion>
          </Card>
        </Container>
      </Box>
      <Footer />
    </>

  );
}

export default App


