/** @jsxImportSource @emotion/react */
import { Container, Typography, Box } from "@mui/material";
import "../assets/css/App.css";
import { Footer } from "../components/footer";
import { Navbar } from "../components/navbar";
import "../assets/css/App.css";
const bg = require("../assets/img/bg-blau.jpg");

function Nutzungsbedingungen() {
    return (
        <>
            <Navbar />
            <Box sx={{ marginY: "48px", paddingX: { xs: 0, sm: 0 } }}>
                {/* Content */}
                <Container className="tos" maxWidth="md" sx={{ paddingX: { xs: 2, sm: 4 }, position: "relative", zIndex: 10 }}>
                    <Typography variant="h1">Allgemeine Nutzungsbedingungen für die Nutzung der Plattform und der
                        damit verbundenen Dienste
                    </Typography>
                    <Typography variant="body1">Aby GmbH, Wienbrücke 6, 27711 Osterholz-Scharmbeck</Typography>
                    <Typography variant="h2">1. Vertragsgegenstand</Typography>
                    <Typography variant="body1">Die folgenden Allgemeinen Geschäftsbedingungen der Aby GmbH regeln
                        ausschließlich das Vertragsverhältnis zwischen Ihnen als Kunden (im
                        Folgenden: Auftraggeber) und myAby (im Folgenden: Auftragnehmer) als
                        Erbringer der Dienstleistung. Der Einbeziehung von entgegenstehenden
                        Bestimmungen, die der Auftraggeber verwendet, wird widersprochen,
                        soweit nicht im Einzelfall etwas anderes vereinbart wurde.</Typography>
                    <Typography variant="h2">2. Vertragspartner</Typography>
                    <Typography variant="body1">2.1 Der Schüler, der sich bei myaby.de anmeldet, ist Vertragspartner
                        und Kunde des Auftragnehmers. Dieser muss bei der Anmeldung
                        uneingeschränkt geschäftsfähig sein.</Typography>
                    <Typography variant="body1">2.2 Ist er nicht uneingeschränkt geschäftsfähig, so muss beim
                        Vertragsschluss die Genehmigung seines gesetzlichen Vertreters
                        vorgelegt werden. Erst nach Eingang dieser Genehmigung beim
                        Auftragnehmer wird dieser Vertrag wirksam. Zugangsgewährungen werden
                        erst nach Vorlage der Genehmigung des gesetzlichen Vertreters
                        freigeschaltet.</Typography>
                    <Typography variant="body1">2.3 Für alle Aktivitäten derjenigen, denen der Auftraggeber Zugang zum
                        myaby.de-Portal gewehrt hat, ist dieser dem Auftragnehmer gegenüber
                        verantwortlich. Diese Personen werden im folgenden „Mitschüler“
                        genannt.</Typography>
                    <Typography variant="body1">2.4 Der Auftraggeber verpflichtet sich dazu, nur Schülern seiner
                        Schule Zugang zum myAby-Portal zu gewähren. Sofern er oder Mitschüler
                        anderen Personen als Angehörigen (Schülern, Lehrern) seiner Schule
                        Zugang zum myAby-Portal gewähren, ist der Auftragnehmer zur Abmahnung
                        des Auftraggebers, im Wiederholungsfalle auch zur Kündigung des
                        Vertrages aus wichtigem Grunde ohne Einhaltung einer Frist berechtigt.</Typography>
                    <Typography variant="body1">2.5 Andere Schüler, auch wenn sie Aufgaben beispielsweise als Admins
                        übernehmen, stehen in keiner vertraglichen Beziehung zum
                        Auftragnehmer.</Typography>
                    <Typography variant="h2">3. Leistungsbeschreibung</Typography>
                    <Typography variant="body1">3.1 Der Auftragnehmer - stellt eine Verwaltungssoftware für
                        verschiedene Belange rund um das Abitur bereit.</Typography>
                    <Typography variant="body1">3.2 Eine Erweiterung oder Einschränkung des Umfangs der Software ist
                        dem Auftragnehmer jederzeit vorbehalten. Der Auftragnehmer wird den
                        Auftraggeber bei einer wesentlichen Änderung der Software
                        unterrichten.</Typography>
                    <Typography variant="body1">3.3 Der Auftraggeber ist im Falle einer wesentlichen Änderung der
                        Software berechtigt, dass Vertragsverhältnis zu kündigen.</Typography>
                    <Typography variant="body1">3.4 Dem Auftraggeber wird für Inhalte Speicherplatz in einer Größe von
                        15 GB eingeräumt.</Typography>
                    <Typography variant="h2"> 4. Gebührenverordnung</Typography>
                    <Typography variant="body1"> 4.1 Die Nutzung des myAby-Portals ist grundsätzlich kostenfrei für
                        jeden Auftraggeber und Nutzer.</Typography>
                    <Typography variant="h2">5. Leistungsstörungen</Typography>
                    <Typography variant="body1">5.1 Der Auftragnehmer ist dazu verpflichtet, Störungen oder
                        Fehlfunktionen ihrer Software in angemessener Zeit abzustellen. Dem
                        Kunden obliegt es, den Auftragnehmer über Störungen oder
                        Fehlfunktionen unverzüglich zu unterrichten.</Typography>
                    <Typography variant="body1">5.2 Eine Dauer der Störung von bis zu sieben Tagen beginnend mit dem
                        Eingang der Störungsmeldung des Auftraggebers beim Auftragnehmer gilt
                        als unwesentlich.</Typography>
                    <Typography variant="body1">5.3 Für den Verlust von Daten haftet der Auftragnehmer nicht, sofern
                        der Datenverlust von ihm nicht vorsätzlich oder grob fahrlässig
                        verursacht wurde.</Typography>
                    <Typography variant="h2">6. Laufzeit des Vertrags</Typography>
                    <Typography variant="body1">Die Nutzung des Portals ist bis 30. August des bei der Anmeldung
                        angegebenen Abiturjahres, höchstens jedoch sechsunddreißig Monate lang
                        möglich.</Typography>
                    <Typography variant="h2">7. Pflichten und Verantwortung des Kunden</Typography>
                    <Typography variant="body1">7.1 Wird das Portal vom Auftraggeber oder seinen Mitschülern für
                        andere Zwecke, als in den oben aufgeführten Leistungen beschrieben,
                        genutzt, so ist der Auftragnehmer nach Abmahnung zur Kündigung des
                        Vertrages aus wichtigem Grunde ohne Einhaltung einer Frist berechtigt.
                        Der Auftragnehmer ist berechtigt, Inhalte die nicht dem Zweck der App
                        entsprechen, zu beseitigen.</Typography>
                    <Typography variant="body1">7.2 Texte, Bilder (z.B. Grafiken) und Lichtbilder unterliegen, sofern
                        sie nicht gemeinfrei sind, dem Urheberrecht. Für die Beachtung der
                        urheberrechtlichen Vorschriften ist der Auftraggeber verantwortlich.
                        Diese Verantwortung trägt er auch für diese Personen, denen er Zugang
                        zum aby.de-Portal gewährt hat. Der Auftragnehmer ist bei Bekanntwerden
                        von Verstößen gegen das Urheberrecht durch den Auftraggeber oder seine
                        Mitschüler zur Sperrung der das Urheberrecht verletzenden Inhalte und
                        zur Abmahnung des Auftraggebers, im Wiederholungsfalle auch zur
                        Kündigung des Vertrages aus wichtigem Grunde ohne Einhaltung einer
                        Frist berechtigt..</Typography>
                    <Typography variant="body1">7.3 Das gilt auch im Falle anderer rechtswidriger Inhalte.
                        Insbesondere kann die Veröffentlichung von Lichtbildern, auf denen
                        Personen abgebildet sind, ohne deren Zustimmung rechtswidrig sein.</Typography>
                    <Typography variant="body1">7.4 Der Auftragnehmer wird den Auftraggeber über die Sperrung von
                        Inhalten soweit unterrichten, wie diese Unterrichtung ohne
                        Rechtsverletzung möglich ist.</Typography>
                    <Typography variant="body1">7.5 Wird der Grund, der den Auftragnehmer zu einer Kündigung des
                        Vertrages berechtigt, durch einen Mitschüler des Auftraggebers
                        verursacht, so kann der Auftragnehmer statt der Kündigung des
                        Vertrages diesem Mitschüler auch den weiteren Zugang zum Portal
                        entziehen.</Typography>
                    <Typography variant="h2">8. Datenschutz</Typography>
                    <Typography variant="body1">8.1 Die Verarbeitung von Daten dritter Personen (z.B. Mitschüler,
                        Lehrer) erfolgt im Auftrag des Kunden. Der Kunde ist Verantwortlicher
                        für die Daten im Sinne des Art. 4 Abs. 7 DSGVO, die Aby GmbH ist
                        Auftragsverarbeiter im Sinne des Art. 4 Abs. 8 DSGVO. Die Weisungen
                        zur Nutzung der Daten erfolgt durch Konfiguration und Bedienung der
                        Software oder per E-Mail durch den Kunden.</Typography>
                    <Typography variant="body1">8.2 Es werden persönliche Daten über den Kunden und seine Mitschüler
                        zur Nutzung des Systems und zur Rechnungsstellung erhoben. Die
                        Erfassung dieser Daten dient lediglich für der Erfüllung eigener
                        Geschäftszwecke gemäß Art. 6 Abs. 1 lit. b DSGVO, vor allem für die
                        Kommunikation mit dem Kunden, zur Leistungserbringung und zur
                        Rechnungsstellung, erhoben, gespeichert, gegebenenfalls verändert oder
                        übermittelt und nur gemäß Art. 6 Abs. 1 lit. c und lit. d DSGVO vor
                        allem aufgrund gesetzlicher Verpflichtung, beispielsweise zu
                        steuerlichen Zwecken, an Dritte übermittelt oder genutzt.</Typography>
                    <Typography variant="body1">8.3 Die Mitarbeiter der Aby GmbH werden nur zu begründeten Zwecken auf
                        die Daten zugreifen, die der Kunde oder andere Personen, denen er
                        Zugang zum myaby.de-Portal eingeräumt hat, hochgeladen haben. In jedem
                        Fall wahren die Mitarbeiter der Aby GmbH Stillschweigen über die
                        Inhalte. In Fällen von Rechtsverletzungen kann die Aby GmbH Inhalte
                        und Daten löschen und / oder sperren.</Typography>
                    <Typography variant="body1">8.4 Die Aby GmbH räumt sich das Recht zur Durchführung statistischer
                        Analysen ein, dabei sind unter keinen Umständen Rückschlüsse auf
                        einzelne Personen oder Kunden möglich. Rechtsgrundlage hierfür ist
                        Art. 6 Abs. 1 lit. f. DSGVO.</Typography>
                    <Typography variant="body1">8.5 Der Kunde ist verpflichtet seine Mitschüler bzw. andere Benutzer
                        über diese Datenschutzbedingungen zu unterrichten und ihr
                        Einverständnis einzuholen, bevor er ihnen Zugang zum Portal gewährt
                        oder Daten über sie erfasst.</Typography>
                    <Typography variant="body1">8.6 Die Aby GmbH trifft angemessene technische und organisatorische Maßnahmen zum Schutz der Daten.
                    </Typography>
                    <Typography variant="body1">8.7 Die Aby GmbH setzt Unterauftragnehmer zur Erfüllung seiner
                        Aufgaben einsetzt, sofern diese unter besonderer Berücksichtigung der
                        Eignung der von diesen getroffenen technischen und organisatorischen
                        Maßnahmen im Sinne von Art. 32 DSGVO ausgewählt wurden. Der Kunde
                        zeigt sich damit einverstanden, dem Kunden wird eine Liste der
                        Unterauftragnehmer auf Anfrage zugänglich gemacht.
                    </Typography>
                    <Typography variant="h2">9. Ende des Vertrages
                    </Typography>
                    <Typography variant="body1">9.1 Der Auftragnehmer ist nach Ablauf der Vertragslaufzeit zur
                        Löschung aller Daten berechtigt, die vom Auftraggeber oder den
                        Personen, denen er Zugang zum aby.de-Portal gewährt hat, hochgeladen
                        worden sind.</Typography>
                    <Typography variant="body1">9.2 Diese Daten – auch in Sicherheitskopien – werden in einer Frist
                        von sechs Monaten nach Ablauf der Vertragslaufzeit vom Auftragnehmer
                        gelöscht. Im Falle einer Kündigung des Vertrages aus wichtigem Grunde
                        durch den Auftragnehmer gilt diese Frist nicht.</Typography>
                    <Typography variant="h2">10. Haftung</Typography>
                    <Typography variant="body1">10.1 Für grobe Fahrlässigkeit und Vorsatz haftet der Auftragnehmer.
                        Ferner haftet der Auftragnehmer für die fahrlässige Verletzung von
                        Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des
                        Vertrages überhaupt erst ermöglicht, deren Verletzung die Erreichung
                        des Vertragszwecks gefährdet und auf deren Einhaltung der Nutzer
                        regelmäßig vertrauen darf. Im letztgenannten Fall haftet der
                        Auftragnehmer jedoch nur für den vorhersehbaren, vertragstypischen
                        Schaden. Für die leicht fahrlässige Verletzung anderer haftet der
                        Auftragnehmer nicht, außer im Falle der in den bevorstehenden Sätzen
                        genannten Pflichten.</Typography>
                    <Typography variant="body1">10.2 Gleiches gilt für Erfüllungsgehilfen des Auftragnehmers und
                        Pflichtverletzungen durch gesetzliche Vertreter.</Typography>
                    <Typography variant="body1">10.3 Bei Verletzung von Leben, Körper und Gesundheit gelten die
                        vorstehenden Haftungsausschlüsse nicht.</Typography>
                    <Typography variant="h2">11. Schlussbestimmungen</Typography>
                    <Typography variant="body1">Für sämtliche Rechtsbeziehungen der Parteien gilt das Recht der
                        Bundesrepublik Deutschland.</Typography>
                </Container>
            </Box>
            <Footer />
        </>
    );
}

export default Nutzungsbedingungen;
