import React from "react"
import { Stack, Link, Container, Typography, Box } from "@mui/material"
import { css } from "@emotion/react";
import { Link as RouterLink } from "react-router-dom"
const logo = require("../assets/img/aby-logo.png");

export const Navbar = () => {
    return (
        <Box sx={{ paddingY: "16px", backgroundColor: "white", width: "100%", boxShadow: 1 }}>
            <Container maxWidth="md">
                <RouterLink to="/" style={{textDecoration: "none"}}>
                    <Stack className="App-header" direction="row" spacing={2} alignItems="center">
                        <Link>
                            <Box sx={{ width: 48 }}>
                                <img
                                    src={logo}
                                    className="App-logo"
                                    alt="logo"
                                    style={{ width: "100%" }}
                                />
                            </Box>
                        </Link>
                        <Typography fontWeight={"300"}>ABY Support</Typography>
                    </Stack></RouterLink>
            </Container>
        </Box>
    )
}