import React from "react"
import { Stack, Link, Container, Box, Typography } from "@mui/material"
import  { Link as RouterLink } from "react-router-dom";

export const Footer = () => {
  return (
    <Box sx={{ backgroundColor: "white", width: "100%" }}>
      <Box sx={{ paddingY: "24px", width: "100%"}}>
        <Container maxWidth={"md"} >
        <Stack direction={{ sm: "row" }}
          justifyContent="space-between"
          alignItems="center" flexWrap={"wrap"}
          spacing={{ xs: 2, sm: 0 }}>
          <Link sx={{color: "#030a33", fontWeight: 400}} underline="none" href="https://youraby.de/datenschutz/">Datenschutzerklärung</Link>
          <RouterLink style={{textDecoration: "none"}} to="/nutzungsbedingungen"><Link sx={{color: "#030a33", fontWeight: 400}} underline="none" >Nutzungsbedingungen</Link></RouterLink>
          <Link sx={{color: "#030a33", fontWeight: 400}} underline="none" href="https://youraby.de/impressum/">Impressum</Link>
          <Link sx={{color: "#030a33", fontWeight: 400}} underline="none" href="mailto:info@youraby.de">Kontakt</Link>
        </Stack>
        </Container>
      </Box>
      <Box sx={{ paddingY: "24px", width: "100%"}}>
        <Container maxWidth={"md"}  >
          <Stack alignItems="center">
            <Typography sx={{color: "black", fontWeight: "100"}}>© Aby GmbH {new Date().getFullYear()}</Typography>
          </Stack>
        </Container>
      </Box>
    </Box>
  )
}